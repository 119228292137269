import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faSpinner } from '@fortawesome/pro-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { StyledBadge } from 'components/common/Badge'
import { useInView } from 'react-intersection-observer'
import ProposalListItem from './ProposalListItem'
import SurveyListItem from './SurveyListItem'
import { TProposal } from './ProposalsTable.types'
import { CollectiveProposalType } from 'graphql/generated'
import Typography, { ITypograhyProps } from 'components/common/Typography'
import { TEvent } from 'hooks/useControlledInput'
import { useBreakpoint } from 'hooks/useBreakpoint'
import ContentWrapper from 'components/ContentWrapper'
import CollectiveDetailsSectionWrapper from '../CollectiveDetails/CollectiveDetailsSectionWrapper'
import SearchInput from '../Common/SearchInput'
import { twMerge } from 'tailwind-merge'

interface IProps {
  proposals: TProposal[]
  onEndReached?: Callback
  loading?: boolean
  title: string | React.ReactNode
  emptyText: string | React.ReactNode
  showBadge?: boolean
  children?: React.ReactNode
  containerClassName?: string
  headerTextProps?: ITypograhyProps
  showCollectiveName?: boolean
  displaySearch?: boolean
  searchString?: string
  onSearchStringChange?: (e: string | TEvent) => void
  clearSearchString?: () => void
  hideExpandButtonOnMobile?: boolean
}

function ProposalsTable({
  title,
  emptyText,
  showBadge = false,
  proposals,
  onEndReached,
  loading = false,
  children,
  containerClassName,
  headerTextProps,

  showCollectiveName,
  displaySearch = false,
  searchString,
  onSearchStringChange,
  clearSearchString,
  hideExpandButtonOnMobile = true
}: IProps) {
  const { ref, inView } = useInView()
  useEffect(() => {
    if (inView) {
      onEndReached?.()
    }
  }, [inView, onEndReached])

  const { isSm } = useBreakpoint()

  const [showContent, setShowContent] = useState(
    hideExpandButtonOnMobile || isSm
  )

  useEffect(() => {
    if (isSm) {
      setShowContent(true)
    }
  }, [isSm])

  if (!proposals.length && !loading) {
    return (
      <ContentWrapper classNames={containerClassName}>
        <Typography
          {...{
            fontWeight: 'bold',
            size: 'lg',
            color: 'darkGray',
            ...headerTextProps
          }}
        >
          {title}
        </Typography>

        {displaySearch && onSearchStringChange && clearSearchString && (
          <div className="flex sm:justify-end mt-4">
            <div className="w-full sm:max-w-xs">
              <SearchInput
                searchString={searchString}
                onSearchStringChange={onSearchStringChange}
                clearSearchString={clearSearchString}
              />
            </div>
          </div>
        )}

        <div className={'flex justify-center items-center'}>
          <FontAwesomeIcon
            icon={faCheck}
            size={'lg'}
            className={'mr-3'}
            color="gray"
          />
          <Typography color="gray">
            {searchString ? `No results for '${searchString}'` : emptyText}
          </Typography>
        </div>
      </ContentWrapper>
    )
  }

  return (
    <ContentWrapper classNames={containerClassName}>
      <CollectiveDetailsSectionWrapper
        title={
          <StyledBadge
            color={'red'}
            content={String(proposals.length)}
            invisible={!showBadge}
          >
            <Typography
              {...{
                fontWeight: 'bold',
                size: 'lg',
                color: 'darkGray',
                ...headerTextProps
              }}
            >
              {title}
            </Typography>
          </StyledBadge>
        }
        hideExpand={hideExpandButtonOnMobile || isSm}
        show={showContent}
        setShow={setShowContent}
      />

      {showContent && (
        <>
          {displaySearch && onSearchStringChange && clearSearchString && (
            <div className="flex sm:justify-end mt-4">
              <div className="w-full sm:max-w-xs">
                <SearchInput
                  searchString={searchString}
                  onSearchStringChange={onSearchStringChange}
                  clearSearchString={clearSearchString}
                />
              </div>
            </div>
          )}

          <div
            className={twMerge(
              'rounded-lg overflow-hidden border-gray-200 mt-4',
              !loading && 'border'
            )}
          >
            <div className="bg-white divide-y divide-gray-200 w-full">
              {proposals.map(proposal =>
                proposal.type === CollectiveProposalType.COMMANDS ? (
                  <ProposalListItem
                    key={`proposal-${proposal.id}`}
                    proposal={proposal}
                    showCollectiveName={showCollectiveName}
                  />
                ) : (
                  <SurveyListItem
                    key={`proposal-${proposal.id}`}
                    proposal={proposal}
                    showCollectiveName={showCollectiveName}
                  />
                )
              )}
            </div>
          </div>

          {loading && (
            <div className={'flex justify-center py-3'}>
              <FontAwesomeIcon icon={faSpinner} spin size={'2x'} />
            </div>
          )}

          {children}

          <div ref={ref}>{`\u2004`}</div>
        </>
      )}
    </ContentWrapper>
  )
}

export default ProposalsTable
