import dynamic from 'next/dynamic'
import Spinner from 'components/Spinner'
import MembershipRequiredModal from './MembershipRequiredModal'
import BaseModal from 'components/modals/BaseModal'
import {
  useCollectiveQuery,
  useEditAnnouncementMutation,
  usePostAnnouncementMutation
} from 'graphql/generated'
import { useState } from 'react'
import { TAnnouncement } from '../CollectiveAnnoucements/CollectiveAnnouncementTypes'
import { useControlledInput } from 'hooks'
import { noop } from 'lodash'
import { parseGqlErrors } from 'hooks/useQueryErrorHandler'
import { Button, Checkbox } from 'components/common'

const QuillNoSSRWrapper = dynamic(() => import('components/QuillNoSSR'), {
  ssr: false,
  // eslint-disable-next-line react/display-name
  loading: () => <Spinner />
})

export default function CreateCollectiveAnnouncementModal({
  onClose = noop,
  collectiveId,
  announcement
}: {
  onClose?: Callback
  collectiveId: string
  announcement?: TAnnouncement
}) {
  const [body, setBody] = useControlledInput(announcement?.bodyHtml || '')
  const editMode = !!announcement
  const [sendPush, setSendPush] = useState<boolean>(false)
  const [saving, setSaving] = useState(false)

  const { data: { collective } = {}, loading: collectiveLoading } =
    useCollectiveQuery({
      variables: { collectiveId, proposalConnection: { first: 5 } }
    })

  const [error, setError] = useState<Maybe<string>>()
  const [postAnnouncement] = usePostAnnouncementMutation({
    variables: {
      collectiveId: collectiveId,
      announcement: {
        bodyHtml: body,
        sendPush
      }
    },
    onError: err => {
      const parsedErrors = parseGqlErrors(err)
      setError(
        parsedErrors['_generic'] ||
          'There was an error posting this announcement. Please try again later.'
      )
    }
  })
  const [editAnnouncement] = useEditAnnouncementMutation({
    variables: {
      announcementId: announcement?.id || '',
      announcement: {
        bodyHtml: body
      }
    },
    onError: err => {
      const parsedErrors = parseGqlErrors(err)
      setError(
        parsedErrors['_generic'] ||
          'There was an error editing this announcement. Please try again later.'
      )
    }
  })

  const handleSubmit = async () => {
    if (body.length > 0) {
      setSaving(true)
      if (!editMode) {
        await postAnnouncement()
        onClose()
      } else {
        await editAnnouncement()
        onClose()
      }
    }
  }

  if (collectiveLoading || saving) {
    return (
      <BaseModal>
        <div className="h-full p-4">
          <Spinner />
        </div>
      </BaseModal>
    )
  }

  if (!collective) {
    return null
  }

  return (
    <MembershipRequiredModal
      collective={collective}
      requiredRole={'privileged'}
      onClose={onClose}
    >
      <BaseModal
        onClose={onClose}
        disableBackdropClick
        classNames="w-full sm:min-w-md"
      >
        <div className="p-4 h-full flex flex-col justify-between">
          <div>
            <div className="mb-4 text-lg">Create Announcement</div>

            {!!error && (
              <div className="flex flex-1 justify-center items-center mb-4">
                <div className="bg-red-200 px-5 py-2 rounded-lg">
                  <p className="text-center font-light">{error}</p>
                </div>
              </div>
            )}
            <QuillNoSSRWrapper
              detailsHtml={body}
              setDetailsHtml={setBody}
              placeholder={`Write your announcement in more detail here`}
            />

            {!editMode && (
              <>
                <div className="my-2">
                  <Checkbox
                    checked={sendPush}
                    onChange={setSendPush}
                    label="Send Notification"
                    size="lg"
                    typographyProps={{
                      fontWeight: 'light',
                      size: 'sm'
                    }}
                  />
                </div>
                {sendPush && (
                  <div className="border rounded-lg p-2 mb-4 border-yellow-100 bg-yellow-50 text-sm">
                    {`Warning: Once you click 'Post Announcement', all DAO members
              will be notified.`}
                  </div>
                )}
              </>
            )}
          </div>

          <div className="mt-4 flex flex-row justify-end space-x-4">
            <Button
              color="outline"
              disabled={false}
              label={'Cancel'}
              onClick={onClose}
            />

            <Button
              color="blue"
              disabled={!body.trim().length}
              label={editMode ? 'Update Announcement' : 'Post Announcement'}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </BaseModal>
    </MembershipRequiredModal>
  )
}
