import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Chip } from 'components/common'
import Typography from 'components/common/Typography'
import { noop } from 'lodash'
import { twMerge } from 'tailwind-merge'

const CollectiveDetailsSectionWrapper: React.FC<{
  title: string | React.ReactNode
  description?: string
  show: boolean
  setShow: CallbackWithParam<boolean>
  badge?: number
  hideExpand?: boolean
}> = ({ title, description, show, setShow, badge, hideExpand }) => {
  const showBadge = !!badge && badge > 0
  return (
    <div
      className={twMerge(
        'flex justify-between  group',
        !hideExpand && 'cursor-pointer'
      )}
      onClick={() => (!hideExpand ? setShow(!show) : noop)}
    >
      <div>
        <div className="flex items-center gap-2">
          <Typography
            fontWeight="bold"
            color="darkGray"
            size="lg"
            component={'div'}
          >
            {title}
          </Typography>
          {showBadge && <Chip size="xs" fill="blue" label={badge} />}
        </div>

        {description && (
          <Typography
            size="sm"
            fontWeight="light"
            color="gray"
            className="pt-2"
          >
            {description}
          </Typography>
        )}
      </div>

      {!hideExpand && (
        <div
          className={
            'group-hover:bg-gray-100 flex justify-center items-center w-[30px] rounded-full'
          }
        >
          <FontAwesomeIcon
            icon={faChevronRight}
            className={show ? 'rotate-90 transition-all' : 'transition-all'}
          />
        </div>
      )}
    </div>
  )
}

export default CollectiveDetailsSectionWrapper
