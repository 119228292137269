import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDeleteAnnouncementMutation } from 'graphql/generated'
import { useQueryErrorHandler } from 'hooks'
import { useState, useMemo, useRef, useEffect } from 'react'
import { useConfirmPromptModal } from 'components/modals/ConfirmPromptModal'
import Spinner from 'components/Spinner'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons'
import RoundedIconButton from 'components/common/RoundedIconButton'
import ModalTrigger from '../../common/ModalTrigger'
import CreateCollectiveAnnouncementModal from '../modals/CreateCollectiveAnnouncementModal'
import CollectiveUserPopover from '../Common/CollectiveUserPopover'
import Autolink from 'components/Autolink'
import classNames from 'classnames'
import { TAnnouncement } from './CollectiveAnnouncementTypes'
import Button from 'components/Button'
import { faPencil, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { humanTime } from 'lib/datetime'

type TListItem = {
  disabled?: boolean
  privileged?: boolean
  announcement: TAnnouncement
  collectiveId: string
  heightLimit?: number
}

export const CollectiveAnnoucement: React.FC<TListItem> = ({
  announcement,
  privileged = false,
  collectiveId,
  heightLimit = 200
}) => {
  const elementRef = useRef<HTMLDivElement>(null)

  const [enableDisplayMoreButton, setEnableDisplayMoreButton] =
    useState<boolean>(false)
  const [displayingMore, setDisplayingMore] = useState<boolean>(false)

  const renderedHtml = useMemo(
    () => (
      <div className="prose break-words max-w-full" ref={elementRef}>
        <Autolink as="div" newWindow>
          {announcement.bodyHtml!}
        </Autolink>
      </div>
    ),
    [announcement.bodyHtml]
  )

  useEffect(() => {
    const enableButton =
      (elementRef.current?.clientHeight || 0) > heightLimit ? true : false
    setEnableDisplayMoreButton(enableButton)
    setDisplayingMore(enableButton ? false : true)
  }, [heightLimit, renderedHtml])

  const onError = useQueryErrorHandler(`Failed to delete annoucement`)
  const [deleteAnnouncement, { loading: deleting }] =
    useDeleteAnnouncementMutation({
      onError
    })

  const confirm = useConfirmPromptModal()

  const onDelete = () =>
    confirm({
      confirmLabel: `Remove Announcement`,
      title: `Remove Announcement`,
      body: `Are you sure you want to remove the accouncement?`,
      onClick: () => {
        deleteAnnouncement({
          variables: {
            id: announcement.id
          }
        })
      }
    })

  if (deleting) {
    return (
      <div className="p-2">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="py-2 px-4 flex w-full overflow-hidden">
      <div className="mt-3 mr-3 bg-blue w-2 h-2 rounded-full" />

      <div className="flex-1 overflow-hidden">
        <div className="flex flex-row justify-between items-start space-x-1 overflow-hidden">
          <div className="mr-4 flex flex-1 flex-col overflow-hidden">
            <div
              className={classNames(
                'flex-1',
                displayingMore ? 'max-h-full' : 'max-h-48 overflow-hidden'
              )}
            >
              {renderedHtml}
            </div>
          </div>

          {privileged && (
            <>
              <ModalTrigger
                trigger={onClick => (
                  <RoundedIconButton
                    onClick={onClick}
                    color="transparent"
                    icon={faPencil}
                  />
                )}
              >
                <CreateCollectiveAnnouncementModal
                  collectiveId={collectiveId}
                  announcement={announcement}
                />
              </ModalTrigger>

              <RoundedIconButton
                onClick={onDelete}
                color="transparent"
                icon={faTrash}
              />
            </>
          )}
        </div>

        <div className="flex justify-between items-centers mt-4">
          <div className="text-sm text-gray-500 font-light lowercase flex items-center space-x-2">
            <p>{`Posted By:`}</p>

            <CollectiveUserPopover
              user={announcement.postedBy.user}
              textStyle="text-xs text-gray-500 font-light"
            />

            <p>{` \u00b7 ${humanTime(announcement.createdAt)}`}</p>
          </div>

          {enableDisplayMoreButton && (
            <div className="flex items-start sm:items-center justify-end">
              <Button
                onClick={() => setDisplayingMore(!displayingMore)}
                color="textOnly"
                size="sm"
                showFocusRing={false}
              >
                <span className="hidden md:inline-block">
                  {displayingMore ? `Show Less` : `Show More`}
                </span>
                <FontAwesomeIcon
                  icon={displayingMore ? faChevronUp : faChevronDown}
                  className="h-2 w-2 rounded-full overflow-hidden ml-2"
                />
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
