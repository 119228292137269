import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { TEvent } from 'hooks/useControlledInput'
import TextField from 'components/common/TextField'
import { twMerge } from 'tailwind-merge'

interface IProps {
  searchString?: string
  onSearchStringChange: (e: string | TEvent) => void
  clearSearchString: () => void
  contextText?: string
}

function SearchInput({
  searchString,
  onSearchStringChange,
  clearSearchString,
  contextText
}: IProps) {
  return (
    <TextField
      icon={faSearch}
      color={searchString ? 'white' : 'gray'}
      iconProps={{ size: '1x' }}
      className="min-h-[34px] focus-within:bg-white border-gray-300"
      placeholder={`Search${contextText ? ` ${contextText}` : ''}`}
      value={searchString}
      onChange={onSearchStringChange}
      postContent={
        <div
          className={twMerge(
            'p-1 flex items-center hover:cursor-pointer',
            !searchString && 'hover:cursor-not-allowed'
          )}
          onClick={clearSearchString}
        >
          <FontAwesomeIcon icon={faTimes} className="text-gray-400" />
        </div>
      }
    />
  )
}

export default SearchInput
